import { Pagination3, Pagination7 } from "../../components/pageProps/shopPage/Pagination";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import { FaDownload } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import "./../ProductDetails/productDetails.css"
import { Link } from "react-router-dom";
export default function Aksesuarebi () {
  const [itemsPerPage, setItemsPerPage] = useState(48);
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState([]);
  const [current, setCurrent] = useState(0);

  const images = [
   "https://i.imgur.com/WGC3d69.jpg",    
   "https://i.imgur.com/ZQkqKdg.jpg"
];
  const nextSlide = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  };
  return (
   
    <div className="flex flex-wrap justify-center gap-4 mt-10" style={{paddingBottom: '15px'}}>

      <Link to="/airpumps" className="w-[300px] flex-shrink-0 border rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105 m-4">
      {/* Image Section */}
      <div className="w-full h-[300px] bg-gray-100 overflow-hidden">
        <img
          src="https://i.imgur.com/cmjDZ1U.png"
          alt="Product"
          className="w-full h-full object-cover"
        />
      </div>
      {/* Content Section */}
      <div className="p-4">
        <h3 className="text-lg font-bold text-gray-800 text-center">
           <br /> 
           ჰაერის კომპრესორი
        </h3>
        <p className="text-sm text-gray-600 text-center mt-2">
          
        </p>
        {/* Button Section */}
        <div className="flex justify-center mt-4">
          <a
            href="#"
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-lg transition-colors"
          >
            იხილეთ მეტი
          </a>
        </div>
      </div>
      </Link>

      <Link to="luqi" className="w-[300px] flex-shrink-0 border rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105 m-4">
        {/* Image Section */}
        <div className="w-full h-[300px] bg-gray-100 overflow-hidden">
          <img
            src="https://i.imgur.com/G6bR8be.jpg"
            alt="Product"
            className="w-full h-full object-cover"
          />
        </div>
        {/* Content Section */}
        <div className="p-4">
          <h3 className="text-lg font-bold text-gray-800 text-center">
            80 ლარი<br /> ჭის თავსახური
          </h3>
          <p className="text-sm text-gray-600 text-center mt-2">
             სამრეწველო ცხიმდამჭერი
          </p>
          {/* Button Section */}
          <div className="flex justify-center mt-4">
            <a
              href="#"
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-lg transition-colors"
            >
              შეიძინე ახლა
            </a>
          </div>
        </div>
      </Link>

      <Link to="cha" className="w-[300px] flex-shrink-0 border rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105 m-4">
        {/* Image Section */}
        <div className="w-full h-[300px] bg-gray-100 overflow-hidden">
          <img
            src="https://i.imgur.com/3m7B3NL.jpg"
            alt="Product"
            className="w-full h-full object-cover"
          />
        </div>
        {/* Content Section */}
        <div className="p-4">
          <h3 className="text-lg font-bold text-gray-800 text-center">
            450 ლარი<br /> ჭა
          </h3>
          <p className="text-sm text-gray-600 text-center mt-2">
              600 x 1000 მმ
          </p>
          {/* Button Section */}
          <div className="flex justify-center mt-4">
            <a
              href="#"
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-lg transition-colors"
            >
              შეიძინე ახლა
            </a>
          </div>
        </div>
      </Link>
    </div>
  
  );
};

