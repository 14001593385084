import React from "react";
import { Link } from "react-router-dom";

const Fanera = () => {
  return (
    <div className="max-w-container mx-auto px-4 mt-10">
      {/* ================= Products Start here =================== */}
      <div className="flex flex-wrap justify-between gap-8 pb-20">
        {/* Product Box 1 */}
        <Link to="/plywood12" > 
          <div className="flex-none w-[220px] h-[370px] bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 hover:shadow-xl">
            <img
              src="https://i.imgur.com/uCk1O4o.jpg"
              alt="Fanera"
              className="w-full h-[200px] object-cover rounded-t-lg transition-transform duration-300 transform hover:scale-110"
            />
            <div className="p-5 flex flex-col justify-between">
              <h2 className="text-xl text-primeColor font-semibold text-center mb-3 hover:text-indigo-600">
                ფანერა I/II ხარისხი
              </h2>
              <p className="text-[#767676] text-center text-sm mb-3">1525 x 1525 მმ</p>
              <a
                href="#"
                className="w-full text-center py-2 bg-primeColor text-white rounded-full mt-auto transition-colors duration-300 hover:bg-indigo-600"
              >
                პროდუქტის ნახვა
              </a>
            </div>
          </div>
        </Link>

        {/* Product Box 2 */}
        <Link to="/plywood23">
          <div className="flex-none w-[220px] h-[370px] bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 hover:shadow-xl">
            <img
              src="https://i.imgur.com/4m1t8rc.jpg"
              alt="Fanera"
              className="w-full h-[200px] object-cover rounded-t-lg transition-transform duration-300 transform hover:scale-110"
            />
            <div className="p-5 flex flex-col justify-between">
              <h2 className="text-xl text-primeColor font-semibold text-center mb-3 hover:text-indigo-600">
                ფანერა II/III ხარისხი
              </h2>
              <p className="text-[#767676] text-center text-sm mb-3">1525 x 1525 მმ</p>
              <a
                href="#"
                className="w-full text-center py-2 bg-primeColor text-white rounded-full mt-auto transition-colors duration-300 hover:bg-indigo-600"
              >
                პროდუქტის ნახვა
              </a>
            </div>
          </div>
        </Link>

        {/* Product Box 3 */}
        <Link to="/plywood34">
          <div className="flex-none w-[220px] h-[370px] bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 hover:shadow-xl">
            <img
              src="https://i.imgur.com/Ifw13jV.jpg"
              alt="Fanera"
              className="w-full h-[200px] object-cover rounded-t-lg transition-transform duration-300 transform hover:scale-110"
            />
            <div className="p-5 flex flex-col justify-between">
              <h2 className="text-xl text-primeColor font-semibold text-center mb-3 hover:text-indigo-600">
                ფანერა III/IV ხარისხი
              </h2>
              <p className="text-[#767676] text-center text-sm mb-3">1525 x 1525 მმ</p>
              <a
                href="#"
                className="w-full text-center py-2 bg-primeColor text-white rounded-full mt-auto transition-colors duration-300 hover:bg-indigo-600"
              >
                პროდუქტის ნახვა
              </a>
            </div>
          </div>
        </Link>
        <Link to="/largeplywood">
          <div className="flex-none w-[300px] h-[365px] bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 hover:shadow-xl">
            <img
              src="https://i.imgur.com/15K27Qf.png"
              alt="Fanera"
              className="w-full h-[200px] object-cover rounded-t-lg transition-transform duration-300 transform hover:scale-110"
            />
            <div className="p-5 flex flex-col justify-between">
              <h2 className="text-xl text-primeColor font-semibold text-center mb-3 hover:text-indigo-600">
                ფანერა III/IV ხარისხი
              </h2>
              <p className="text-[#767676] text-center text-sm mb-0">დიდი ფორმატი</p>

              <p className="text-[#767676] text-center text-sm mb-0">1220 x 2440 მმ</p>
              <a
                href="#"
                className="w-full text-center py-2 bg-primeColor text-white rounded-full mt-auto transition-colors duration-300 hover:bg-indigo-600"
              >
                პროდუქტის ნახვა
              </a>
            </div>
          </div>
        </Link>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default Fanera;
