

export default function Cximdamcheri () {
    return (
        <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
    <div className="max-w-container mx-auto px-4">
      <div style={{marginTop: '60px'}} className="xl:-mt-10 -mt-7">
        {/* <Breadcrumbs title="" prevLocation={prevLocation} /> */}
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
          <div className="h-full xl:col-span-2">
            <div className='butts' >
                <img 
                  className="w-full h-full"
                  src="https://i.imgur.com/AdA7h6f.png"
                />
            </div>
        </div>
        <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
        <div className="flex flex-col gap-5">
            <h2 className="text-4xl font-semibold">ნიჟარისქვეშა ცხიმდამჭერი „KANN“</h2>
            <p className="text-1xl font-semibold">
            ეს არის ლოკალური გამწმენდი სადგური, რომლებიც შექმნილია ცხიმის შემცველი და  ცხიმოვანი ნაერთების  გასაწმენდად და მათი კანალიზაციის სისტემაში შესვლისგან დასაცველად. 
            <br /> აღნიშნული  გამწმწმენდი სადგურები ხელს უშლის ქალაქის საკანალიზაციო  ქსელების დაბინძურებას.
            
            <br />
            <br />
            <br />
            350 ლარი
            </p>
            <p className="text-base text-green-600 font-medium">მარაგშია</p>
          </div>
        </div>
      </div>
      </div>

      <div>
        <div className=" space-x-4  pt-4">
            <button className="bg-blue-500 text-white py-2 px-4  focus:outline-none">ტექნიკური მახასიათებლები</button>
        </div>
        <div className="my-4">
          <div>
            
            <table className="table-auto w-full">
              <tbody>
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2 font-semibold">მწარმოებელი ქვეყანა</td>
                  <td className="border px-4 py-2">ბელორუსია</td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2 font-semibold">ზომა</td>
                  <td className="border px-4 py-2">450 x 350 x 300</td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2 font-semibold">გამტარიანობა</td>
                  <td className="border px-4 py-2">300 ლ/ 1 საათში</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="my-4">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={activeTab === tab.id ? "" : "hidden"}
            >
              {tab.id === "Fiche Technique" && productInfo.ficheTech ? (
                <div>
                  <table className="table-auto w-full">
                    <tbody>
                      {productInfo.ficheTech.map((row) => (
                        <tr key={row.label} className="bg-gray-100">
                          <td className="border px-4 py-2 font-semibold">
                            {row.label}
                          </td>
                          <td className="border px-4 py-2">{row.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="my-4 flex justify-end">
                    
                  </div>
                </div>
              ) : (
                <p>{tab.content}</p>
              )}
            </div>
          ))}
        </div> */}
      </div>
    </div>
    )
}